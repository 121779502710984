import './splash_screen.css'
import Logo from './assets/logo_splash.svg'

function Splash( {isLoading} ) {
    return (
        <div className={isLoading ? 'splash-screen' : 'splash-screen hidden'}>
            <img className='splash-logo' src={Logo} />
        </div>
    );
} 

export default Splash;