import './App.css';
import { useState, useEffect } from 'react';
import Splash from './components/splash_screen';
import Main from './components/main';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      document.body.style.overflow = "auto"
    }, 2000);
  },[])

  return (
    <>
    <Splash isLoading={isLoading}/>
    <Main />
    </>
  );
}

export default App;